import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import './Palvelut.scss'

import hinnasto from '../../images/laatulkv-hinnasto-iso-2025.png'

const Palvelut = () => {
  const [hideMyymassa, toggleHideMyymassa] = useState(true)
  const [hideVuokraamassa, toggleHideVuokraamassa] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='Palvelut animate__animated animate__fadeIn'>
      <Helmet>
        <title>Laatu LKV | Palvelut | Kiinteistönvälitys Hämeenlinna. Kiinteä ja edullinen välityspalkkio. Hyvä ja kokenut kiinteistönvälittäjä. Asunnon myynti kodistasi välittäen.</title>
      </Helmet>
      <h1
        className='customSubTitle'
        style={{ backgroundColor: '#fecbb8', padding: '16px 16px 12px 12px' }}>
        Palvelut
      </h1>

      <div className='palvelutContainer'>
        <Button variant='light' onClick={() => toggleHideMyymassa(false)} hidden={!hideMyymassa}>
          <h3 className='customSubTitle'>Myymässä?</h3>
        </Button>
        <div className='palvelutCol' hidden={hideMyymassa} onClick={() => toggleHideMyymassa(true)}>
          <h3 className='customSubTitle'>Myymässä?</h3>
          <h5>1. MAKSUTON ARVIOKÄYNTI</h5>
          <p>
            Pyydä meidät veloituksettomalle arviokäynnille, kun suunnittelet kotisi myyntiä.
            Arviokäynnillä tutustumme kotiisi ja käymme yhdessä läpi myyntiprosessiin liittyviä
            asioita sekä lähialueesi toteutuneita kauppahintoja ja myyntiaikoja. Annamme sinulle
            rehellisen hinta-arvion perustuen edellä mainittuihin asioihin sekä aluetuntemukseen ja
            markkinatilanteeseen. Oikea hinnoittelu on yksi tärkeimmistä tekijöistä onnistuneessa
            asuntokaupassa.
          </p>
          <h5>2. TOIMEKSIANTOSOPIMUS</h5>
          <p>
            Toimeksiantosopimus tehdään joko kirjallisesti, SKVL:n laatimalle sopimuspohjalle tai
            sähköisesti järjestelmämme kautta. Toimeksiantosopimus on voimassa vähintään kaksi
            kuukautta ja maksimissaan neljä kuukautta. Tämän jälkeen toimeksiantosopimuksesta
            tehdään tarvittaessa erillinen jatkosopimus.
          </p>
          <h5>3. KOTISI KUVAUS- JA MYYNTISTAILAUS</h5>
          <p>
            Hyvät, valoisat ja todenmukaiset valokuvat ovat hyvin tärkeässä roolissa onnistuneessa
            asuntokaupassa. Pyrimme aina siihen, että jokaisen myytävän kohteemme parhaat puolet ja
            potentiaali saadaan esille ja tätä kautta kohteesta saadaan paras mahdollinen hinta ja
            lyhennetään myyntiaikaa. Sen vuoksi teemmekin myytäviin kohteisiimme tarpeen mukaan
            myyntistailauksia ennen valokuvausta. Lisäksi käytämme suurimmassa osassa myytävistä
            kohteistamme lisäpalveluna ammattivalokuvausta.
          </p>
          <h5>4. KATTAVA NETTINÄKYVYYS</h5>
          <p>
            Kotisi esitellään sekä Oikotiellä että Etuovella. Näiden lisäksi myynti-ilmoitukset
            julkaistaan myös Laatu LKV:n nettisivuilla, Facebook-sivuilla ja Instagramissa.
            Maksullinen mainonta edellä mainituissa kuuluu pakettiimme. Teksti on myös tärkeässä
            roolissa myynti-ilmoituksessa ja siihen mietimmekin yhdessä tärkeät seikat, joita itse
            arvostat omassa kodissasi.
          </p>
          <h5>5. YKSITYISESITTELYT</h5>
          <p>
            Panostamme henkilökohtaiseen sekä laadukkaaseen asiakaspalveluun ja näin ollen suosimme
            pääsääntöisesti yksityisesittelyjä. Yksityisesittelyssä pystytään käymään monipuolisesti
            kotisi lisäksi läpi myös varastotilat ja alueen palvelut jne. Näin kaikki ostopäätökseen
            tarvittavat asiat saadaan käytyä potentiaalisen ostajan kanssa läpi luottamuksellisesti
            heti paikan päällä. Ostopäätökset tehdään aina lähes poikkeuksetta yksityisesittelyssä.
          </p>
          <h5>6. KAUPANTEKO</h5>
          <p>
            Onnistunut myyntiprosessi johtaa kaupantekoon, joka pidetään pankissa tai tehdään
            sähköisesti. Kauppakirjan saa aina etukäteen tarkistettavaksi ja se käydään vielä
            yhdessä läpi kaupantekotilaisuudessa. Välityspalkkio maksetaan kaupanteon yhteydessä
            suoraan kauppahinnasta ja jos kauppoihin asti ei jostain syystä päästä, ei
            välityspalkkiota tarvitse myöskään maksaa. Kaikki kaupantekoon liittyvät järjestelyt,
            kauppakirjojen teko ym. sisältyvät välityspalkkioon, sähköisestä DIAS-asuntokaupasta
            peritään 45€ myyjältä ja ostajalta.
          </p>
        </div>
        <Button
          variant='light'
          onClick={() => toggleHideVuokraamassa(false)}
          hidden={!hideVuokraamassa}>
          <h3 className='customSubTitle'>Vuokraamassa?</h3>
        </Button>
        <div
          className='palvelutCol'
          hidden={hideVuokraamassa}
          onClick={() => toggleHideVuokraamassa(true)}>
          <h3 className='customSubTitle'>Vuokraamassa?</h3>
          <p>
            Laatu LKV:n palveluihin sisältyy myös vuokranvälityspalvelut, jotka toteutetaan samalla
            ammattitaidolla ja laatutasolla kuin myytävienkin kohteiden palvelut. Myös
            vuokranvälityspalkkiot ovat kiinteitä huonelukuun perustuvia palkkioita.
          </p>
          <h5>1. ALKUKARTOITUS</h5>
          <p>
            Sovimme tapaamisen asunnolle, jossa käymme yhdessä läpi huoneiston sekä
            vuokrausprosessin. Arvioimme huoneiston vuokratason ja käymme läpi
            toimeksiantosopimuksen ehdot. Toimeksiantosopimus tehdään joko kirjallisesti, SKVL:n
            laatimalle sopimuspohjalle tai sähköisesti järjestelmämme kautta. Lisäksi täytämme
            asuntotietolomakkeen, jossa käydään läpi mm. asunnon kunto ja varustus.
          </p>
          <h5>2. VALMISTELUT</h5>
          <p>
            Toimeksiantaja tilaa isännöitsijältä tarvittavat asiakirjat (isännöitsijäntodistus,
            energiatodistus ja pohjakuva). Näiden sekä asuntotietolomakkeen perusteella laadimme
            vuokrailmoituksen. Ilmoitusta varten tarvitaan myös hyvät kuvat sekä myyvä
            ilmoitusteksti. Vuokrailmoitus julkaistaan Oikotiellä.
          </p>
          <h5>3. VUOKRALAISEN ETSIMINEN</h5>
          <p>
            Markkinoimme ja esittelemme kohdetta potentiaalisille vuokralaisille. Selvitämme
            vuokra-asunnon hakijan luotto- ja taustatiedot. Esittelemme vuokralaisehdokkaat ja sinä
            teet lopullisen päätöksen.
          </p>
          <h5>4. VUOKRASOPIMUS JA KÄYTÄNNÖN KUVIOT</h5>
          <p>
            Oikean vuokralaisen löydyttyä laadimme vuokrasopimuksen ja tarvittavat liitteet.
            Hoidamme myös avaintenluovutuksen, tarkastamme huoneiston vuokralaisen kanssa ja teemme
            siitä kirjallisen raportin. Vuokraaminen on näin sinulle mahdollisimman vaivatonta.
          </p>
        </div>
      </div>
      <div className='valitysPalkkio'>
        <h2 className='customSubTitle'>Kiinteä välityspalkkio</h2>
        <p>
          Kiinteät välityspalkkiomme tuovat selkeyttä asunnon myynti- ja vuokrausprosessiisi.
          Arvonlisäveron 25,5 % sisältävät palkkiomme perustuvat asunto-osakkeen huonelukuun, ja
          kiinteistöistä on niiden koosta riippumatta oma kiinteä palkkionsa.
        </p>

        <p>
          Asuntoa myytäessä kiinteään välityspalkkioomme sisältyy näkyvyys sekä Oikotiellä että
          Etuovella. Näiden lisäksi myynti-ilmoitukset julkaistaan myös Laatu LKV:n nettisivuilla,
          Facebook-sivuilla ja Instagramissa. Maksullinen mainonta Facebookissa ja Instagramissa
          kuuluu pakettiimme. Kiinteään välityspalkkioon sisältyy myös asunnon myyntistailaus
          tarvittaessa ja tietysti kaikki prosessit asunnon myyntiin laittamisesta aina kaupantekoon
          ja kauppakirjojen tekoon asti.
        </p>

        <p>
          Asuntoa vuokrattaessa kiinteään välityspalkkioomme sisältyy näkyvyys Oikotiellä. Tämän
          lisäksi vuokrailmoitukset julkaistaan myös Laatu LKV:n nettisivuilla, Facebook-sivuilla ja
          Instagramissa. Maksullinen mainonta Facebookissa ja Instagramissa kuuluu pakettiimme.
          Kiinteään välityspalkkioomme sisältyy kaikki prosessit asunnon vuokralle laittamisesta
          aina vuokrasopimuksen tekoon, avainten luovutukseen ja asunnon tarkastukseen asti.
        </p>

        <p>
          Asiakirjakulut ja mahdolliset asiakkaan toivomat (ei pakolliset) lisäpalvelut
          (ammattivalokuvaus, virtuaaliesittely, videoesittely, lehtimainos ym) veloitetaan
          erikseen. Asunto-osakkeissa asiakirjakulut maksetaan isännöitsijätoimiston laskun mukaan
          (yleensä n.50€-210€), kiinteistöissä asiakirjakulujen hinta riippuu tarvittavista
          papereista (yleensä n.100€). Asiakirjat voi halutessaan tilata suoraan itse tai me voimme
          tilata ne puolestasi, tästä sovitaan toimeksiantosopimusta tehdessä.
        </p>
        <img src={hinnasto} alt='Hinnasto' />
      </div>
      <div className='muutPalvelut'>
        <h2 className='customSubTitle'>Muut palvelut</h2>
        <p>Arviokäynnit muuta kuin myyntiä varten, ei arviokirjaa: 400€ (sis. alv 25,5 %)</p>

        <p>Pelkän kauppakirjan tekeminen:</p>

        <p>– osakehuoneistot 500 € (sis. alv 25,5 %), asiakas maksaa itse asiakirjakulut</p>

        <p>– kiinteistöt 700 € (sis. alv 25,5 %), asiakas maksaa itse asiakirjakulut</p>
      </div>
    </div>
  )
}

export default Palvelut
